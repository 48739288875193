import { createContext, useEffect, useMemo, useState } from "react";
import { AppContainer } from "./layout.styles";
import AppHeader from "./appHeader";
import SidebarLayout from "./sidebar/SidebarLayout";
import { SidebarItemsProps } from "@maersk-global/community-react-sidebar";
import { ThemeProvider } from "@maersk-global/community-react/src/index";
import {
  Roles,
  buildConcreteRolesFromTokenRoles,
  buildRolesFromTokenRoles,
  getNavItems,
  getSiteNavItems,
  getUserInfo,
} from "../../utils";
import { toast } from "../common/toast";
import { getSiteUserDashboard } from "../../services/SiteDashboard/Index";
import { UserMappedSites } from "../../models/SiteDashboard";

export interface UserContextProps {
  email: string;
  roles: Array<string>;
}

export const UserContext = createContext<UserContextProps>({
  email: "",
  roles: [],
});
export const SitesContext = createContext<{
  userMappedSites: UserMappedSites[];
}>({
  userMappedSites: [],
});

export default function Layout() {
  const [userName, setUserName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [roles, setRoles] = useState<Array<string>>([]);
  const [isValidUser, setIsValidUser] = useState<boolean>(true);
  const [navItems, setNavItems] = useState<SidebarItemsProps[]>([]);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [userMappedSites, setUserMappedSites] = useState<UserMappedSites[]>([]);
  const [isSiteRole, setIsSiteRole] = useState<boolean>(false);
  const [concreteRole, setConcreteRole] = useState<string>("");

  useEffect(() => {
    let userInfo = getUserInfo();

    if (userInfo && userInfo.userName && userInfo.roles) {
      setUserName(userInfo.userName);
      setEmail(userInfo.email);
      let tokenRoles = buildRolesFromTokenRoles(userInfo.roles);
      setRoles(tokenRoles);

      let concreteRole = buildConcreteRolesFromTokenRoles(tokenRoles);
      setConcreteRole(concreteRole);

      if (tokenRoles.length === 0) setIsValidUser(false);
    } else {
      setIsValidUser(false);
    }
  }, []);

  useEffect(() => {
    let isSiteRole =
      roles.includes(Roles.SITECOORDINATOR) ||
      roles.includes(Roles.SITEOWNER) ||
      roles.includes(Roles.SITEUSER) ||
      roles.includes(Roles.BRADMIN);
    setIsSiteRole(isSiteRole);

    if (isSiteRole) {
      getUserMappedSites();
    } else {
      let navItems = getNavItems(roles);
      setNavItems(navItems);
    }
  }, [roles]);

  useEffect(() => {
    if (!isValidUser) {
      toast(
        "Unauthorized",
        "You do not have enough permission to access this page. Please contact your administrator.",
        "error"
      );
    }
  }, [isValidUser]);

  const getUserMappedSites = () => {
    getSiteUserDashboard()
      .then((data) => {
        setUserMappedSites(data);
      })
      .catch((error) => {
        toast("Error", error.message, "error");
        return [];
      });
  };

  useEffect(() => {
    if (isSiteRole) {
      let siteNavItems = getSiteNavItems(userMappedSites);
      setNavItems(siteNavItems);
    }
  }, [userMappedSites, isSiteRole]);

  const updateSite = (site: UserMappedSites) => {
    let index = userMappedSites.findIndex((item) => item.id === site.id);
    let updatedSites = [...userMappedSites];
    updatedSites[index] = site;

    setUserMappedSites(updatedSites);
  };

  const contextValue = useMemo(() => {
    return { userMappedSites, updateSite };
  }, [userMappedSites]);

  const userContextValue = useMemo(
    () => ({ email: email, roles: roles }),
    [email, roles]
  );

  return (
    <>
      {isValidUser && (
        <ThemeProvider>
          <AppContainer>
            <UserContext.Provider value={userContextValue}>
              <AppHeader
                userName={userName}
                isSidebarExpanded={isExpanded}
                role={concreteRole}
              />
              {isSiteRole && userMappedSites.length > 0 ? (
                <SitesContext.Provider value={contextValue}>
                  <SidebarLayout
                    navItems={navItems}
                    isExpanded={() => setIsExpanded(!isExpanded)}
                  />
                </SitesContext.Provider>
              ) : (
                <SidebarLayout
                  navItems={navItems}
                  isExpanded={() => setIsExpanded(!isExpanded)}
                />
              )}
            </UserContext.Provider>
          </AppContainer>
        </ThemeProvider>
      )}
    </>
  );
}
